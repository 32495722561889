import axios from 'axios';

export const getList = params => axios.post('/business/balanceRecharge/list', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const detail = params => axios.post('/business/balanceRecharge/detail', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

