import axios from "axios/index";
/**
 * 车辆仓库管理js文件
 * @param params
 * @returns {Promise<any | {code: number, message: string}>}
 */
export const getList = params => axios.post('/business/carWarehouse/list', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

export const create = params => axios.post('/business/carWarehouse/add', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

export const update = params => axios.post('/business/carWarehouse/update', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

export const del = id => axios.post('/business/carWarehouse/delete', {id}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

export const detail = id => axios.post('/business/carWarehouse/detail', {id}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

export const getWarehouseList = params => axios.post('/business/carWarehouse/getWarehouseList', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});


