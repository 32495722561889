import axios from 'axios';

/**
 * 查看操作人日志
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const detail = params => axios.post('/tool/logData/queryByOperator', params).then(res => res ? res.data : {
  code: 500,
  message: '服务器无法连接,请稍后重试'
});

/**
 * 查看日志
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const list = params => axios.post('/tool/logData/list', params).then(res => res ? res.data : {
  code: 500,
  message: '服务器无法连接,请稍后重试'
});

