/**** 工作台接口 ***/
import axios from 'axios';

/**
 * 运维人员状态统计
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const getYunList = params => axios.post('/business/workTime/yunList', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
/**
 * 运维人员详细信息
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const getYunUserList = params => axios.post('/business/workTime/yunUserList', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
/**
 * 查询新任务，处理中任务数量
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const getTaskCount = params => axios.post('/business/deviceVehicleTask/getTaskCount', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
/**
 * 获取运维任务超时列表
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const getOverTimeTaskList = params => axios.post('/business/deviceVehicleTask/getOverTimeTaskList', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
/**
 * 获取电瓶取出超时列表
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const getOverTimeBatteryList = params => axios.post('/business/deviceBattery/getOverTimeBatteryList', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 统计骑行订单数据
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const getRideCount = params => axios.post('/business/rideRecord/rideCount', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
/**
 * 统计骑行费用数据
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const getRideFee = params => axios.post('/business/rideRecord/rideFee', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
/**
 * 统计骑行用户数据
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const getRideUser = params => axios.post('/business/rideRecord/rideUser', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
/**
 * 获取所有一级权限
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const getParentList = params => axios.post('/business/permission/parentList', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 通过id获取所有下级权限
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const getSonById = params => axios.post('/business/permission/getSonById', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
/**
 * 模糊查询3级权限
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const queryLikeList = params => axios.post('/business/permission/queryLikeList', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
/**
 * 常用权限
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const queryCommonList = params => axios.post('/business/permission/commonList', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
/**
 * 保存常用权限
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const saveCommon = params => axios.post('/business/permission/saveCommon', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
/**
 * 获取运维动态
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const ywDynamicList = params => axios.post('/business/yw/list', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
/**
 * 获取运维动态趋势
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const ywDynamicCount = params => axios.post('/business/yw/ywCount', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

