import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import api from './api';
import '@/assets/ant_design_icon/iconfont.css';
import '@/assets/fullcalendar-vars.css'
import vue3SeamlessScroll from "vue3-seamless-scroll";
const app = createApp(App);
// 设置api接口
app.config.globalProperties.$api = api;
app.config.globalProperties.copyRight = `版权所有：${process.env.VUE_APP_COPYRIGHT}`;
app.config.globalProperties.productName = process.env.VUE_APP_PRODUCT_NAME;
app.config.globalProperties.$amapKey = "7395bdb3aaeda907f3812e3a40deea06";
// 文件下载
app.config.globalProperties.blobFile = function (data, fileName, failCallBack, successCallback) {
  let fileReader = new FileReader();
  fileReader.onload = async function () {
    try {
      let jsonData
      if (data.type && data.type === 'application/json') {
        jsonData = JSON.parse(await data.text());
      } else {
        jsonData = JSON.parse(data);
      }
      if (jsonData.code) {
        if (failCallBack) {
          failCallBack(jsonData);
        }
      }
    } catch (err) {
      // 解析成对象失败，说明是正常的文件流
      let blob = new Blob([data]);
      let downloadElement = document.createElement('a');
      let href = window.URL.createObjectURL(blob); //创建下载的链接
      downloadElement.style.display = 'none';
      downloadElement.href = href;
      downloadElement.download = fileName; //下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      window.URL.revokeObjectURL(href); //释放掉blob对象
      blob = null;
      if (successCallback) {
        successCallback();
      }
    }
  };
  fileReader.readAsText(data);
};

Date.prototype.format = function (fmt) {
  let o = {
    'M+': this.getMonth() + 1,                 //月份
    'd+': this.getDate(),                    //日
    'h+': this.getHours(),                   //小时
    'm+': this.getMinutes(),                 //分
    's+': this.getSeconds(),                 //秒
    'q+': Math.floor((this.getMonth() + 3) / 3), //季度
    'S': this.getMilliseconds()             //毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
    }
  }
  return fmt;
};

import permission from './plugins/permission';
app.use(permission, {
  store: store
});

import layoutBreadcrumb from '@/layout/layout-breadcrumb-none';
app.component('layout-breadcrumb', layoutBreadcrumb);

import tablePage from '@/components/table-page';
app.component('table-page', tablePage);
import formPage from '@/components/form-page';
app.component('form-page', formPage);
app.use(vue3SeamlessScroll);
app.use(store).use(router).mount('#app');
