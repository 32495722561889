import axios from 'axios';

/**
 * 查询
 * @param params
 * @returns {Promise<any | {code: number, message: string}>}
 */
export const getList = params => axios.post('/business/electronicFence/list', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

export const create = params => axios.post('/business/electronicFence/create', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

export const update = params => axios.post('/business/electronicFence/update', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

export const disable = params => axios.post('/business/electronicFence/disable', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const enable = params => axios.post('/business/electronicFence/enable', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const detail = params => axios.post('/business/electronicFence/detail', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

export const getByAreaId = params => axios.post('/business/electronicFence/getByAreaId', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

