import axios from 'axios';

/**
 * 查询已生效费率
 * @param params
 * @returns {Promise<any | {code: number, message: string}>}
 */
export const getList = params => axios.post('/business/feeStandardRule/list', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

export const items = feeStandardRuleId => axios.post('/business/feeStandardRule/items', {feeStandardRuleId}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const exportInfo = params => axios.post('/business/feeStandardRule/export', params, {
    responseType: 'blob',
    timeout: 20000
}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
