/**** 黑名单管理 ***/
import axios from 'axios';

/**
 * 查询禁停区列表信息
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const getList = params => axios.post('/business/np/list', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
/**
 * 修改禁停区使用状态
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const changeStatus = params => axios.post('/business/np/status', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
/**
 * 新增禁停区
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const npAdd = params => axios.post('/business/np/add', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
/**
 * 修改禁停区
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const update = params => axios.post('/business/np/update', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
/**
 * 获取单个禁停区信息
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const getById = params => axios.post('/business/np/getCode', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
/**
 * 区域id获取禁停区信息
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const listByArea = params => axios.post('/business/np/listByArea', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});