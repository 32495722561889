import axios from 'axios';

/**
 * 获取流程图
 * @param processDefinitionId 流程定义id
 * @returns {Promise<{code: number, data: any} | {code: number, message: string}>}
 */
export const getBpmnXml = processDefinitionId => axios.post(`/flow/flowRest/getDefinitionXml`, {processDefinitionId}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});