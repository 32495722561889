import axios from 'axios';

/**
 * 查询数据字典列表信息
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const getList = params => axios.post('/business/dictionary/list', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const detail = params => axios.post('/business/dictionary/detail', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const update = params => axios.post('/business/dictionary/update', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const del = params => axios.post('/business/dictionary/del', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const disable = params => axios.post('/business/dictionary/disable', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const enable = params => axios.post('/business/dictionary/enable', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 通过code 查询 明细
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getItemByCode = params => axios.post('/business/dictionary/getItemByCode', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});


/**
 * 数据字典子项
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const itemCreate = params => axios.post('/business/dictionary/itemCreate', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const itemUpdate = params => axios.post('/business/dictionary/itemUpdate', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const itemDel = params => axios.post('/business/dictionary/itemDel', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const itemDetail = params => axios.post('/business/dictionary/itemDetail', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const itemDisable = params => axios.post('/business/dictionary/itemDisable', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const itemEnable = params => axios.post('/business/dictionary/itemEnable', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

export const dictItems = params => axios.post('/business/innerOpenApi/dict/dictItems', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
