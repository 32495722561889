import axios from 'axios';

/**
 * 查询停车区列表信息
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const getList = params => axios.post('/business/park/list', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
/**
 * 修改停车区使用状态
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const changeStatus = params => axios.post('/business/park/status', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
/**
 * 新增停车区
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const parkAdd = params => axios.post('/business/park/add', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
/**
 * 修改停车区
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const update = params => axios.post('/business/park/update', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
/**
 * 获取单个停车区信息
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const getById = params => axios.post('/business/park/getCode', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
/**
 * 区域id获取停车区信息
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const listByArea = params => axios.post('/business/park/listByArea', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});