import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_icon = _resolveComponent("el-icon");
  return _openBlock(), _createElementBlock("div", {
    class: "icon-button",
    onClick: _cache[0] || (_cache[0] = (...args) => $options.onClick && $options.onClick(...args))
  }, [_createVNode(_component_el_icon, {
    class: _normalizeClass($props.iconClass),
    size: $props.size
  }, null, 8, ["class", "size"])]);
}