/**** 催缴记录 ***/
import axios from 'axios';

/**
 * 查询催缴记录列表信息
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const getList = params => axios.post('/business/shareTransactionFlow/getList', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const getFlowDetail = params => axios.post('/business/shareTransactionFlow/getFlowDetail', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

