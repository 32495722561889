import axios from 'axios';

export const getList = params => axios.post('/business/customerSuggestInfo/getList', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const make = params => axios.post('/business/customerSuggestInfo/make', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
