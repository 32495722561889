import axios from 'axios';

/**
 * 查询用户权限
 * @param params 查询参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const getUserPermission = params => axios.post('/uac/permission/getMenus', params).then(res => res ? res.data : {
  code: 500,
  message: '服务器无法连接,请稍后重试'
});

/**
 * 查询权限定义
 * @param params 查询参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const list = params => axios.post('/uac/permission/list', params).then(res => res ? res.data : {
  code: 500,
  message: '服务器无法连接,请稍后重试'
});

/**
 * 创建权限
 * @param params 权限定义参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const create = params => axios.post('/uac/permission/create', params).then(res => res ? res.data : {
  code: 500,
  message: '服务器无法连接,请稍后重试'
});

/**
 * 修改权限
 * @param params 权限定义参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const update = params => axios.post('/uac/permission/update', params).then(res => res ? res.data : {
  code: 500,
  message: '服务器无法连接,请稍后重试'
});

/**
 * 删除权限定义
 * @param permissionId 权限定义id
 * @return {Promise<AxiosResponse<any>>}
 */
export const del = (permissionId) => axios.post('/uac/permission/delete', {permissionId}).then(res => res ? res.data : {
  code: 500,
  message: '服务器无法连接,请稍后重试'
});

/**
 * 查看权限定义
 * @param permissionId 权限定义id
 * @return {Promise<AxiosResponse<any>>}
 */
export const detail = (permissionId) => axios.post('/uac/permission/detail', {permissionId}).then(res => res ? res.data : {
  code: 500,
  message: '服务器无法连接,请稍后重试'
});

/**
 * 绑定角色
 * @param permissionId 权限定义id
 * @param roles 角色id集合
 * @return {Promise<AxiosResponse<any>>}
 */
export const bindRole = (permissionId, roles) => axios.post('/uac/permission/bindRole', {
  permissionId,
  roles
}).then(res => res ? res.data : {
  code: 500,
  message: '服务器无法连接,请稍后重试'
});

/**
 * 查看API绑定的权限
 * @param url API Url
 * @return {Promise<AxiosResponse<any>>}
 */
export const listByUrl = (url) => axios.post('/uac/permission/listByUrl', {url}).then(res => res ? res.data : {
  code: 500,
  message: '服务器无法连接,请稍后重试'
});

/**
 * 查看角色绑定的权限
 * @param roleId 角色id
 * @return {Promise<AxiosResponse<any>>}
 */
export const listByRole = (roleId) => axios.post('/uac/permission/listByRole', {roleId}).then(res => res ? res.data : {
  code: 500,
  message: '服务器无法连接,请稍后重试'
});

/**
 * 查看机构绑定的权限
 * @param orgId 机构id
 * @return {Promise<AxiosResponse<any>>}
 */
export const listByOrg = (orgId) => axios.post('/uac/permission/listByOrg', {orgId}).then(res => res ? res.data : {
  code: 500,
  message: '服务器无法连接,请稍后重试'
});

/**
 * 查看机构绑定的权限（树形）
 * @param orgId 机构id
 * @return {Promise<AxiosResponse<any>>}
 */
export const menuByOrg = (orgId) => axios.post('/uac/permission/menuByOrg', {orgId}).then(res => res ? res.data : {
  code: 500,
  message: '服务器无法连接,请稍后重试'
});
