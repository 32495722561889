import axios from 'axios';

/**
 * 查询收费标准
 * @param params
 * @returns {Promise<any | {code: number, message: string}>}
 */
export const getList = params => axios.post('/business/feeStandardRuleAudit/list', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

export const create = params => axios.post('/business/feeStandardRuleAudit/create', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

export const update = params => axios.post('/business/feeStandardRuleAudit/update', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const copy = params => axios.post('/business/feeStandardRuleAudit/copy', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

export const del = feeStandardRuleAuditId => axios.post('/business/feeStandardRuleAudit/delete', {feeStandardRuleAuditId}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

export const detail = feeStandardRuleAuditId => axios.post('/business/feeStandardRuleAudit/detail', {feeStandardRuleAuditId}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

export const items = feeStandardRuleAuditId => axios.post('/business/feeStandardRuleAudit/items', {feeStandardRuleAuditId}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

export const check = (feeStandardRuleAuditId, pass) => axios.post('/business/feeStandardRuleAudit/check', {
    feeStandardRuleAuditId,
    pass
}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const exportInfo = params => axios.post('/business/feeStandardRuleAudit/export', params, {
    responseType: 'blob',
    timeout: 20000
}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
