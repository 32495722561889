import axios from 'axios';
export const getList = params => axios.post('/business/parkingPayParameter/list', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const detail = params => axios.post('/business/parkingPayParameter/detail', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const save = params => axios.post('/business/parkingPayParameter/save', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
