import "core-js/modules/es.array.push.js";
const CustomTableColumnSlot = (props, content) => {
  return props.column.customRender(props, content);
};
import { ElMessage } from 'element-plus';
import 'element-plus/es/components/message/style/css'

import bus from "@/plugins/bus";
import IconButton from './icon-button';
import TableColumnFilter from './table-column-filter';
export default {
  props: {
    emptyDescription: {
      type: String,
      default: "暂无数据"
    },
    columnSetting: {
      type: Boolean,
      default: true
    },
    tableSize: {
      type: String,
      default: 'large'
    },
    // 获取数据api
    fetchApi: {},
    // api回调，参数[table.tableData, res]，需给table.data赋值
    fetchCallBack: null,
    // 查询参数
    searchParams: {},
    // 是否显示分页
    showPagination: {
      type: Boolean,
      default: true
    },
    // 立即自动获取数据
    immediately: {
      type: Boolean,
      default: true
    },
    // 树形表格时需要
    rowKey: null,
    // 是否多选
    selectionEnable: {
      type: Boolean,
      default: true
    },
    // 是否显示行号
    showRowIndex: {
      type: Boolean,
      default: true
    },
    // 能否选择
    rowSelectEnable: {
      type: Boolean,
      default: false
    },
    columns: {
      type: Array
    },
    linkButtons: {
      type: Array
    },
    topButtons: {
      type: Array
    },
    searchItems: {
      type: Array
    },
    showSummary: {
      type: Boolean,
      default: false
    },
    // 大于几个link按钮时显示更多
    showMoreButtonCount: {
      type: Number,
      default: 3
    },
    searchItemsRules: {}
  },
  watch: {
    'currentPage': function () {
      this.fetch();
    },
    'pageSize': function () {
      this.fetch();
    }
  },
  computed: {
    filterLinkButtons: function () {
      let buttons = [];
      if (this.linkButtons) {
        this.linkButtons.forEach(item => {
          if (this.$hasPermission(item.permissions)) {
            if (item.disabled === undefined) {
              item.disabled = false;
            }
            buttons.push(item);
          }
        });
      }
      return buttons;
    }
  },
  components: {
    CustomTableColumnSlot,
    'icon-button': IconButton,
    'table-column-filter': TableColumnFilter
  },
  data() {
    return {
      tableData: [],
      // 原始定义列
      originColumns: this.columns.concat([]),
      filterColumns: this.columns.concat([]),
      dataTotal: 0,
      currentPage: 1,
      pageSize: 10,
      pageSizeOptions: [10, 20, 30, 40, 50, 100],
      loading: false,
      selectRow: [],
      searchFormModel: {},
      toPath: ''
    };
  },
  methods: {
    submitSearchForm() {
      this.$refs.searchFormRef.validate(valid => {
        if (valid) {
          this.fetch();
        }
      });
    },
    resetSearchForm() {
      if (this.$refs.searchFormRef) {
        this.$refs.searchFormRef.clearValidate();
      }
      this.initSearchFormModel();
      this.submitSearchForm();
    },
    columnFilterChange(columnLabels) {
      const result = this.originColumns.filter(v => columnLabels.indexOf(v.label) >= 0);
      // 按照顺序添加列
      result.sort((a, b) => {
        return columnLabels.indexOf(a.label) - columnLabels.indexOf(b.label);
      });
      this.filterColumns.splice(0, this.filterColumns.length);
      this.$nextTick(() => {
        result.forEach(item => this.filterColumns.push(item));
      });
    },
    /**
     * link按钮更多点击
     * @param command 命令
     * @param row 操作的行数据
     */
    handleMoreCommand(command, row) {
      let linkButton = this.linkButtons.filter(v => v.code === command);
      if (linkButton.length > 0) {
        linkButton[0].click(row);
      }
    },
    handleSelectionChange(selection) {
      this.selectRow = selection;
    },
    // 单选
    handleCurrentChange(row) {
      if (!this.selectionEnable && this.rowSelectEnable) {
        this.selectRow = [row];
      }
    },
    fetch() {
      this.loading = true;
      let searchValues = Object.assign({}, this.searchFormModel);
      if (this.searchItems) {
        this.searchItems.forEach(item => {
          if (item.propItems) {
            item.propItems.forEach((p, index) => {
              if (!this.searchFormModel[item.prop]) {
                // 值可能为空
                delete searchValues[p];
              } else {
                searchValues[p] = this.searchFormModel[item.prop][index];
              }
            });
            delete searchValues[`${item.prop}`];
          }
        });
      }
      let requestParam;
      if (this.showPagination) {
        requestParam = Object.assign({}, {
          page: this.currentPage,
          size: this.pageSize
        }, this.searchParams, searchValues);
      } else {
        requestParam = Object.assign({}, this.searchParams, searchValues);
      }
      this.fetchApi(requestParam).then(res => {
        if (this.showPagination) {
          if (res.code === 200) {
            this.dataTotal = res.data.total;
          }
          if (this.fetchCallBack) {
            this.fetchCallBack(this.tableData, res);
          } else {
            if (res.code === 200) {
              this.tableData = res.data.records;
            } else {
              ElMessage({
                message: res.message,
                type: 'error'
              });
            }
          }
        } else {
          if (this.fetchCallBack) {
            this.fetchCallBack(this.tableData, res);
          } else {
            if (res.code === 200) {
              this.tableData = res.data;
            } else {
              ElMessage({
                message: res.message,
                type: 'error'
              });
            }
          }
        }
        this.loading = false;
      });
    },
    initSearchFormModel() {
      if (this.searchItems) {
        this.searchItems.forEach(item => {
          var _item$defaultValue;
          this.searchFormModel[item.prop] = (_item$defaultValue = item.defaultValue) !== null && _item$defaultValue !== void 0 ? _item$defaultValue : '';
        });
      }
    }
  },
  mounted() {
    if (this.immediately) {
      if (this.$refs.searchFormRef) {
        this.$refs.searchFormRef.validate(valid => {
          if (valid) {
            this.fetch();
          }
        });
      } else {
        this.fetch();
      }
    }
    this.initSearchFormModel();
    bus.on(this.$route.path, this.fetch);
  },
  beforeUnmount() {
    bus.off(this.$route.path, this.fetch);
  }
};