import axios from 'axios';

/**
 * 查询用户
 * @param params 查询参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const list = params => axios.post('/uac/user/list', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 修改用户自己密码
 * @param oldPwd 旧密码
 * @param newPwd 新密码
 * @return {Promise<AxiosResponse<any>>}
 */
export const changePwd = (oldPwd, newPwd) => axios.post('/uac/user/changePwd', {
    oldPwd,
    newPwd
}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 未登录修改密码
 * @param orgCode 机构编码
 * @param userName 用户名
 * @param oldPwd 旧密码
 * @param newPwd 新密码
 * @param captchaId 验证码id
 * @param captchaCode 验证码
 * @return {Promise<AxiosResponse<any>>}
 */
export const changePwdNotLogin = (orgCode, userName, oldPwd, newPwd, captchaId, captchaCode) => axios.post('/uac/user/changePwdNotLogin', {
    orgCode,
    userName,
    oldPwd,
    newPwd,
    captchaId,
    captchaCode
}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});


/**
 * 创建用户
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const create = params => axios.post('/uac/user/create', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 修改用户信息
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const update = params => axios.post('/uac/user/update', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 锁定用户
 * @param userId 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const lock = userId => axios.post('/uac/user/lock', {userId}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 解锁用户
 * @param userId 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const unLock = userId => axios.post('/uac/user/unLock', {userId}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 禁用用户
 * @param userId 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const disable = userId => axios.post('/uac/user/disable', {userId}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 启用用户
 * @param userId 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const enable = userId => axios.post('/uac/user/enable', {userId}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 查询部门下的用户
 * @param deptId 部门id
 * @return {Promise<AxiosResponse<any>>}
 */
export const queryByDept = deptId => axios.post('/uac/user/queryByDept', {deptId}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 查询角色已绑定用户
 * @param roleId 角色id
 * @return {Promise<AxiosResponse<any>>}
 */
export const queryByRole = roleId => axios.post('/uac/user/queryByRole', {roleId}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 查看用户信息
 * @param userId 用户Id
 * @return {Promise<AxiosResponse<any>>}
 */
export const detail = userId => axios.post('/uac/user/detail', {userId}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 重置用户密码
 * @param userId 用户id
 * @param newPwd 新密码
 * @return {Promise<AxiosResponse<any>>}
 */
export const forceChangePwd = (userId, newPwd) => axios.post('/uac/user/forceChangePwd', {
    userId,
    newPwd
}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 用户用户的证书信息
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const usbKeys = params => axios.post('/uac/user/usbKeys', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 禁用用户证书
 * @param userId 用户Id
 * @param usbKeyId 证书Id
 * @return {Promise<AxiosResponse<any>>}
 */
export const disableUsbKey = (userId, usbKeyId) => axios.post('/uac/user/disableUsbKey', {
    userId,
    usbKeyId
}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 启用用户证书
 * @param userId 用户Id
 * @param usbKeyId 证书Id
 * @return {Promise<AxiosResponse<any>>}
 */
export const enableUsbKey = (userId, usbKeyId) => axios.post('/uac/user/enableUsbKey', {
    userId,
    usbKeyId
}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
/**
 * 查询仓库管理人员列表
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const getWarehousePersonList = params => axios.post('/uac/user/warehousePersonalList', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});