import * as user from './user';
import * as permission from "./permission";
import * as requestPath from "./requestPath";
import * as role from "./role";
import * as dept from "./dept";
import * as org from "./org";
import * as area from "./area";

export default {
  user,
  permission,
  requestPath,
  role,
  dept,
  org,
  area
};
