import axios from "axios";
import router from "../router";
import {ElLoading} from 'element-plus'

axios.defaults.timeout = 10000;
axios.defaults.baseURL = '/api';
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(
  config => {
    let token = sessionStorage.getItem('token');
    if (token && !config.headers.Authorization) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);
axios.interceptors.response.use(
  response => {
    if (response && (response.status === 401 || (response.data && response.data.code === 401))) {
      sessionStorage.removeItem("token");
      const loading = ElLoading.service({fullscreen: true});
      loading.close();
      router.replace({
        path: '/login',
        query: {
          redirect: router.currentRoute.value.query && router.currentRoute.value.query.redirect ?
            router.currentRoute.value.query.redirect : router.currentRoute.value.fullPath
        },
      });
    } else if (response.headers['authorization']) {
      let token = sessionStorage.getItem('token');
      if (token) {
        console.log('token delay');
        sessionStorage.setItem("token", response.headers['authorization']);
      }
    }
    return response;
  },
  error => {
    if (error.response) {
      const loading = ElLoading.service({fullscreen: true});
      loading.close();
      switch (error.response.status) {
        case 401:
          sessionStorage.removeItem("token");
          router.replace({
            path: '/login',
            query: {
              redirect: router.currentRoute.value.query && router.currentRoute.value.query.redirect ?
                router.currentRoute.value.query.redirect : router.currentRoute.value.fullPath
            },
          });
          break;
        case 403:
          router.push({
            name: '403'
          });
          break;
        case 404:
          router.push({
            name: '404'
          });
          break;
        case 500:
          router.push({
            name: '500'
          });
          break;
        case 503:
          error.response.data = {code: 503, message: '服务不可用'};
          return Promise.resolve(error.response);
      }
      return Promise.reject(error.response.data)   // 返回接口返回的错误信息
    }
  }
);
