import axios from "axios";

require('./config');

import uac from './uac';
import log from './log';
import business from './business';
import report from './report';
import flow from './flow';
import tool from './tool';

/**
 * 用户名密码登录
 * @param orgCode 机构编码
 * @param userName 用户名
 * @param password 密码
 * @param captchaId 验证码id
 * @param captchaCode 验证码
 * @return {Promise<AxiosResponse<any>>}
 */
const normalLogin = (orgCode, userName, password, captchaId, captchaCode) => {
    return axios.post('/uac/auth/login', {orgCode, userCode: userName, password: password, captchaId, captchaCode}).then(res => res ? res.data : {
        code: 500,
        message: '服务器无法连接,请稍后重试'
    });
};

/**
 * 获取验证码
 * @return {Promise<AxiosResponse<any>>}
 */
const getCaptcha = params => axios.post('/uac/auth/getCaptcha', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 注销
 * @param params
 * @return {Promise<AxiosResponse<any>>}
 */
const logout = params => axios.post('/uac/auth/logout', params);

export default {
    uac,
    log,
    business,
    report,
    flow,
    tool,
    normalLogin,
    logout,
    getCaptcha
}
