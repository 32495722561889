/**
 * 菜单缓存
 */
export default {
  namespaced: true,
  state: {
    openedList: [],
    currentPage: null
  },
  getters: {
    getOpenedList(state) {
      return state.openedList;
    },
    getCurrentPage(state) {
      return state.currentPage;
    }
  },
  actions: {
    setOpenedList({commit}, openedList) {
      if (Array.isArray(openedList)) {
        commit('mergeState', {openedList});
      } else {
        throw new TypeError('openedList is not Array');
      }
    },
    addOpenedPage({commit}, page) {
      commit('doAddOpenedPage', page);
    },
    setCurrentPage({commit}, page) {
      commit('mergeState', {currentPage: page});
    },
    closePage({commit}, path) {
      commit('doClosePage', path);
    },
    clearAllPage({commit}, keepFirst = false) {
      commit('doClearAllPage', keepFirst);
    },
    clearOtherPage({commit}) {
      commit('doClearOtherPage');
    }
  },
  mutations: {
    /**
     * 通用改变状态的值
     * @param {Object} state        状态
     * @param {Object} mergeObject  需要改变的对象 {key: value}
     */
    mergeState(state, mergeObject) {
      for (const key in mergeObject) {
        state[key] = mergeObject[key];
      }
    },
    doAddOpenedPage(state, page) {
      if (page && !state.openedList.find(v => v.path === page.path)) {
        state.openedList.push(page);
      }
    },
    doClosePage(state, path) {
      state.openedList.map((item, index) => {
        if (item.path === path) {
          state.openedList.splice(index, 1);
        }
      });
    },
    doClearAllPage(state, keepFirst) {
      state.openedList.splice(keepFirst ? 1 : 0);
    },
    doClearOtherPage(state) {
      let currentPath = state.currentPage.path;
      let currentIndex = 0;
      state.openedList.forEach((item, index) => {
        if (item.path === currentPath) {
          currentIndex = index;
        }
      });
      if (currentIndex === 0) {
        state.openedList.splice(1);
      } else {
        state.openedList.splice(currentIndex + 1);
        state.openedList.splice(1, currentIndex - 1);
      }
    }
  }
};
