export default {
  name: "icon-button",
  props: {
    'iconClass': {
      type: String
    },
    size: {
      type: String,
      default: '18px'
    }
  },
  methods: {
    onClick() {
      this.$emit('iconClick');
    }
  }
};