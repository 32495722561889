import axios from "axios/index";

/**
 *
 * @param params
 * @returns {Promise<any | {code: number, message: string}>}
 */
export const getList = params => axios.post('/business/deviceBattery/list', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const detail = params => axios.post('/business/deviceBattery/detail', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const create = params => axios.post('/business/deviceBattery/create', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const update = params => axios.post('/business/deviceBattery/update', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

//获取中控状态
export const getController = params => axios.post('/business/deviceBattery/controllerState', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

//修改状态
export const updateState = params => axios.post('/business/deviceBattery/updateState', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

export const exportTemplate = () => axios.post('/business/deviceBattery/exportTemplate', null, {
    responseType: 'blob',
    timeout: 20000
}).then(res => res ? res.data : {
    code: 500,
    msg: '服务器无法连接,请稍后重试',
});
export const importFile = formData => axios.post('/business/deviceBattery/importFile', formData).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

