import axios from 'axios';

/**
 * 查询部门列表
 * @param params 查询参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const list = params => axios.post('/uac/dept/list', params).then(res => res ? res.data : {
  code: 500,
  message: '服务器无法连接,请稍后重试'
});

/**
 * 查看部门信息
 * @param deptId 部门id
 * @return {Promise<AxiosResponse<any>>}
 */
export const detail = (deptId) => axios.post('/uac/dept/detail', {deptId}).then(res => res ? res.data : {
  code: 500,
  message: '服务器无法连接,请稍后重试'
});

/**
 * 创建部门
 * @param params 部门参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const create = params => axios.post('/uac/dept/create', params).then(res => res ? res.data : {
  code: 500,
  message: '服务器无法连接,请稍后重试'
});

/**
 * 修改部门
 * @param params 部门参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const update = params => axios.post('/uac/dept/update', params).then(res => res ? res.data : {
  code: 500,
  message: '服务器无法连接,请稍后重试'
});

/**
 * 删除部门
 * @param deptId 部门id
 * @return {Promise<AxiosResponse<any>>}
 */
export const del = (deptId) => axios.post('/uac/dept/delete', {deptId}).then(res => res ? res.data : {
  code: 500,
  message: '服务器无法连接,请稍后重试'
});

/**
 * 绑定用户
 * @param deptId 部门id
 * @param userIds 绑定的用户id列表
 * @return {Promise<AxiosResponse<any>>}
 */
export const bindUser = (deptId, userIds) => axios.post('/uac/dept/bindUser', {
  deptId,
  userIds
}).then(res => res ? res.data : {
  code: 500,
  message: '服务器无法连接,请稍后重试'
});

/**
 * 查看用户的部门
 * @param userId 用户id
 * @return {Promise<AxiosResponse<any>>}
 */
export const queryByUser = (userId) => axios.post('/uac/dept/queryByUser', {userId}).then(res => res ? res.data : {
  code: 500,
  message: '服务器无法连接,请稍后重试'
});

