import axios from "axios/index";

export const getDeviceBatteryMonitorList = params => axios.post('/business/deviceBattery/getMonitorList', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const getDeviceControllerMonitorList = params => axios.post('/business/deviceController/getMonitorList', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const getDeviceFrameMonitorList = params => axios.post('/business/deviceFrame/getMonitorList', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

