import axios from "axios/index";

/**
 * 查询押金规则
 * @param params
 * @returns {Promise<any | {code: number, message: string}>}
 */
export const getList = params => axios.post('/business/depositRule/list', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});


export const create = params => axios.post('/business/depositRule/create', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});


