import axios from 'axios';

/**
 * 查询区域列表
 * @param params 查询参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const list = params => axios.post('/uac/area/list', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 查看区域信息
 * @param id 区域id
 * @return {Promise<AxiosResponse<any>>}
 */
export const detail = (id) => axios.post('/uac/area/detail', {id}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 创建区域
 * @param params 区域参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const create = params => axios.post('/uac/area/create', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 修改区域
 * @param params 区域参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const update = params => axios.post('/uac/area/update', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 删除区域
 * @param id 区域id
 * @return {Promise<AxiosResponse<any>>}
 */
export const del = (id) => axios.post('/uac/area/delete', {id}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 查询角色已绑定的区域
 * @param roleId 角色id
 * @return {Promise<AxiosResponse<any>>}
 */
export const listByRole = (roleId) => axios.post('/uac/area/listByRole', {roleId}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
