import axios from 'axios';

export const getList = params => axios.post('/business/transactionFlow/list', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
/**
 * 批量开票
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const reInvoicing = params => axios.post('/business/transactionFlow/reInvoicing', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

export const refund = params => axios.post('/business/transactionFlow/refund', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const itemList = params => axios.post('/business/transactionFlow/itemList', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
/**
 * 导出交易流水
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const exportCount = params => axios.post('/business/transactionFlow/export', params, {
    responseType: 'blob',
    timeout: 20000
}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
