import "core-js/modules/es.array.push.js";
import draggable from 'vuedraggable';
export default {
  name: "table-column-filter",
  components: {
    draggable
  },
  props: {
    originColumns: {
      default: []
    }
  },
  emits: ['on-column-filter'],
  data() {
    return {
      drag: false,
      columns: [],
      checkedColumns: [],
      isIndeterminate: false,
      isAll: true,
      dragOptions: {
        animation: 200
      }
    };
  },
  methods: {
    reset(fireEvent) {
      const oldCheckedColumns = this.checkedColumns.toString();
      this.checkedColumns = [];
      this.columns = [];
      this.originColumns.forEach(item => {
        this.columns.push({
          label: item.label,
          checked: true
        });
        this.checkedColumns.push(item.label);
      });
      this.isIndeterminate = false;
      this.isAll = true;
      if (fireEvent) {
        const hasChange = oldCheckedColumns !== this.checkedColumns.toString();
        if (hasChange) {
          this.$emit('on-column-filter', this.checkedColumns);
        }
      }
    },
    handleCheckAllChange(checkAll) {
      this.checkedColumns = [];
      if (checkAll) {
        this.columns.forEach(item => {
          item.checked = true;
          this.checkedColumns.push(item.label);
        });
        this.isIndeterminate = false;
      }
      this.$emit('on-column-filter', this.checkedColumns);
    },
    handleCheckedColumnIndexChange(value) {
      this.columns.forEach(item => {
        item.checked = value.indexOf(item.label) >= 0;
      });
      this.isIndeterminate = value.length > 0 && value.length < this.columns.length;
      this.isAll = value.length === this.columns.length;
      this.$emit('on-column-filter', this.checkedColumns);
    },
    dragEnd() {
      this.drag = false;
      const oldCheckedColumns = this.checkedColumns.toString();
      this.checkedColumns = [];
      this.columns.forEach(item => {
        if (item.checked) {
          this.checkedColumns.push(item.label);
        }
      });
      const hasChange = oldCheckedColumns !== this.checkedColumns.toString();
      if (hasChange) {
        this.$emit('on-column-filter', this.checkedColumns);
      }
    }
  },
  mounted() {
    this.reset(false);
  }
};