/**** 在停记录管理 ***/
import axios from 'axios';

/**
 * 查询停车记录列表信息
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const getList = params => axios.post('/business/rideRecord/list',params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 查询停车记录导出列表信息
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const getExportList = params => axios.post('/business/rideRecord/getExportList', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const getPhotoPath = params => axios.post('/business/rideRecord/getPhotoPath', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const getLogs = params => axios.post('/business/rideRecord/getLogs', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const detail  = params => axios.post('/business/rideRecord/detail', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const getGPS  = params => axios.post('/business/rideRecord/getGPS', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const changeParkFee  = params => axios.post('/business/rideRecord/changeParkFee', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const exportInfo = params => axios.post('/business/rideRecord/export', params, {
    responseType: 'blob',
    timeout: 100000
}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
