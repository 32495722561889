import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vShow as _vShow, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-269f292c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "table-page"
};
const _hoisted_2 = {
  key: 0,
  class: "table-search"
};
const _hoisted_3 = {
  class: "table-content"
};
const _hoisted_4 = {
  class: "table-toolbar-left"
};
const _hoisted_5 = {
  key: 0
};
const _hoisted_6 = {
  key: 1
};
const _hoisted_7 = {
  class: "el-dropdown-link"
};
const _hoisted_8 = {
  key: 2,
  class: "space-pagination"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  const _component_el_cascader = _resolveComponent("el-cascader");
  const _component_el_date_picker = _resolveComponent("el-date-picker");
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_form = _resolveComponent("el-form");
  const _component_el_row = _resolveComponent("el-row");
  const _component_icon_button = _resolveComponent("icon-button");
  const _component_table_column_filter = _resolveComponent("table-column-filter");
  const _component_el_popover = _resolveComponent("el-popover");
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_custom_table_column_slot = _resolveComponent("custom-table-column-slot");
  const _component_el_divider = _resolveComponent("el-divider");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
  const _component_el_dropdown = _resolveComponent("el-dropdown");
  const _component_el_empty = _resolveComponent("el-empty");
  const _component_el_table = _resolveComponent("el-table");
  const _component_el_pagination = _resolveComponent("el-pagination");
  const _directive_permission = _resolveDirective("permission");
  const _directive_loading = _resolveDirective("loading");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.searchItems && $props.searchItems.length > 0 || _ctx.$slots.searchForm ? (_openBlock(), _createElementBlock("div", _hoisted_2, [!_ctx.$slots.searchForm ? (_openBlock(), _createBlock(_component_el_form, {
    key: 0,
    ref: "searchFormRef",
    inline: true,
    model: $data.searchFormModel,
    "label-width": 120,
    rules: $props.searchItemsRules
  }, {
    default: _withCtx(() => [$props.searchItems && $props.searchItems.length > 0 ? (_openBlock(true), _createElementBlock(_Fragment, {
      key: 0
    }, _renderList($props.searchItems, item => {
      return _openBlock(), _createBlock(_component_el_form_item, {
        label: item.label,
        key: item.label,
        prop: item.prop
      }, {
        default: _withCtx(() => {
          var _item$dateType;
          return [item.type === 'select' && !item.remote ? (_openBlock(), _createBlock(_component_el_select, {
            key: 0,
            modelValue: $data.searchFormModel[item.prop],
            "onUpdate:modelValue": $event => $data.searchFormModel[item.prop] = $event,
            class: _normalizeClass(["form-item-width", item.class]),
            multiple: item.multiple,
            placeholder: `请选择${item.label}`,
            clearable: "",
            onChange: item.change
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.items, (selectItem, index) => {
              return _openBlock(), _createBlock(_component_el_option, {
                key: index,
                label: item.itemProp ? selectItem[item.itemProp.label] : selectItem,
                value: item.itemProp ? selectItem[item.itemProp.value] : selectItem
              }, null, 8, ["label", "value"]);
            }), 128))]),
            _: 2
          }, 1032, ["modelValue", "onUpdate:modelValue", "class", "multiple", "placeholder", "onChange"])) : item.type === 'select' && item.remote ? (_openBlock(), _createBlock(_component_el_select, {
            key: 1,
            modelValue: $data.searchFormModel[item.prop],
            "onUpdate:modelValue": $event => $data.searchFormModel[item.prop] = $event,
            class: _normalizeClass(["form-item-width", item.class]),
            remote: "",
            filterable: "",
            loading: item.loading,
            "remote-method": item.remoteMethod,
            multiple: item.multiple,
            placeholder: `请选择${item.label}`,
            clearable: "",
            onChange: item.change
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.items, (selectItem, index) => {
              return _openBlock(), _createBlock(_component_el_option, {
                key: index,
                label: item.itemProp ? selectItem[item.itemProp.label] : selectItem,
                value: item.itemProp ? selectItem[item.itemProp.value] : selectItem
              }, null, 8, ["label", "value"]);
            }), 128))]),
            _: 2
          }, 1032, ["modelValue", "onUpdate:modelValue", "class", "loading", "remote-method", "multiple", "placeholder", "onChange"])) : item.type === 'cascader' ? (_openBlock(), _createBlock(_component_el_cascader, {
            key: 2,
            options: item.options,
            props: item.props,
            modelValue: $data.searchFormModel[item.prop],
            "onUpdate:modelValue": $event => $data.searchFormModel[item.prop] = $event,
            class: "form-item-width",
            style: _normalizeStyle(item.style),
            placeholder: `请选择${item.label}`,
            clearable: "",
            onChange: item.change
          }, null, 8, ["options", "props", "modelValue", "onUpdate:modelValue", "style", "placeholder", "onChange"])) : item.type === 'date' ? (_openBlock(), _createBlock(_component_el_date_picker, {
            key: 3,
            modelValue: $data.searchFormModel[item.prop],
            "onUpdate:modelValue": $event => $data.searchFormModel[item.prop] = $event,
            type: (_item$dateType = item.dateType) !== null && _item$dateType !== void 0 ? _item$dateType : 'date',
            "value-format": item.valueFormat,
            format: item.valueFormat,
            class: _normalizeClass(["form-item-width", item.class]),
            placeholder: `请选择${item.label}`,
            "start-placeholder": "请选择开始日期",
            "end-placeholder": "请选择截止日期",
            clearable: "",
            shortcuts: item.shortcuts,
            onChange: item.change
          }, null, 8, ["modelValue", "onUpdate:modelValue", "type", "value-format", "format", "class", "placeholder", "shortcuts", "onChange"])) : (_openBlock(), _createBlock(_component_el_input, {
            key: 4,
            modelValue: $data.searchFormModel[item.prop],
            "onUpdate:modelValue": $event => $data.searchFormModel[item.prop] = $event,
            clearable: "",
            class: _normalizeClass(["form-item-width", item.class]),
            placeholder: `请输入${item.label}`,
            onChange: item.change
          }, null, 8, ["modelValue", "onUpdate:modelValue", "class", "placeholder", "onChange"]))];
        }),
        _: 2
      }, 1032, ["label", "prop"]);
    }), 128)) : _createCommentVNode("", true), _createVNode(_component_el_form_item, null, {
      default: _withCtx(() => [_createVNode(_component_el_button, {
        type: "primary",
        onClick: $options.submitSearchForm
      }, {
        default: _withCtx(() => [_createTextVNode("查询")]),
        _: 1
      }, 8, ["onClick"]), _createVNode(_component_el_button, {
        onClick: $options.resetSearchForm
      }, {
        default: _withCtx(() => [_createTextVNode("重置")]),
        _: 1
      }, 8, ["onClick"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["model", "rules"])) : _renderSlot(_ctx.$slots, "searchForm", {
    key: 1
  }, undefined, true)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_3, [$props.topButtons && $props.topButtons.length > 0 || $props.columnSetting || _ctx.$slots.toolBarLeft ? (_openBlock(), _createBlock(_component_el_row, {
    key: 0,
    class: "table-toolbar",
    justify: "space-between"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_renderSlot(_ctx.$slots, "toolBarLeft", {}, undefined, true)]), _createVNode(_component_el_row, {
      class: "table-toolbar-right"
    }, {
      default: _withCtx(() => [$props.topButtons && $props.topButtons.length > 0 ? (_openBlock(), _createBlock(_component_el_row, {
        key: 0
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.topButtons, item => {
          return _withDirectives((_openBlock(), _createBlock(_component_el_button, {
            key: item.code,
            onClick: () => {
              item.click($data.selectRow);
            },
            type: item.type,
            disabled: typeof item.disabled === 'function' ? item.disabled($data.selectRow) : item.disabled
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(typeof item.name === "function" ? item.name($data.selectRow) : item.name), 1)]),
            _: 2
          }, 1032, ["onClick", "type", "disabled"])), [[_directive_permission, item.permissions]]);
        }), 128))]),
        _: 1
      })) : _createCommentVNode("", true), _createVNode(_component_el_row, {
        gutter: 12,
        class: "setting"
      }, {
        default: _withCtx(() => [$props.columnSetting ? (_openBlock(), _createBlock(_component_el_popover, {
          key: 0,
          trigger: "click"
        }, {
          reference: _withCtx(() => [_createVNode(_component_icon_button, {
            iconClass: 'iconfont icon-setting'
          })]),
          default: _withCtx(() => [_createVNode(_component_table_column_filter, {
            originColumns: $data.originColumns,
            onOnColumnFilter: $options.columnFilterChange
          }, null, 8, ["originColumns", "onOnColumnFilter"])]),
          _: 1
        })) : _createCommentVNode("", true)]),
        _: 1
      })]),
      _: 1
    })]),
    _: 3
  })) : _createCommentVNode("", true), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
    data: $data.tableData,
    onSelectionChange: $options.handleSelectionChange,
    onCurrentChange: $options.handleCurrentChange,
    size: $props.tableSize,
    border: "",
    stripe: "",
    "row-key": $props.rowKey,
    "show-summary": $props.showSummary,
    "header-cell-class-name": "table-header-cell",
    "highlight-current-row": $props.rowSelectEnable && !$props.selectionEnable,
    class: "table-content-table"
  }, {
    append: _withCtx(() => [_renderSlot(_ctx.$slots, "append", {}, undefined, true)]),
    empty: _withCtx(() => [_createVNode(_component_el_empty, {
      description: $props.emptyDescription
    }, null, 8, ["description"])]),
    default: _withCtx(() => [$props.selectionEnable ? (_openBlock(), _createBlock(_component_el_table_column, {
      key: 0,
      type: "selection",
      width: "55"
    })) : _createCommentVNode("", true), $props.showRowIndex ? (_openBlock(), _createBlock(_component_el_table_column, {
      key: 1,
      type: "index",
      width: "60",
      label: "#",
      align: "center"
    })) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.filterColumns, column => {
      var _column$align;
      return _withDirectives((_openBlock(), _createBlock(_component_el_table_column, {
        key: column.label,
        prop: column.prop,
        label: column.label,
        width: column.width,
        formatter: column.formatter,
        align: (_column$align = column.align) !== null && _column$align !== void 0 ? _column$align : 'center',
        type: column.type,
        "show-overflow-tooltip": column.showOverflowTooltip
      }, _createSlots({
        _: 2
      }, [column.customRender ? {
        name: "default",
        fn: _withCtx(scope => [_createVNode(_component_custom_table_column_slot, {
          row: scope.row,
          column: column
        }, null, 8, ["row", "column"])]),
        key: "0"
      } : column.type && column.typeSlot ? {
        name: "default",
        fn: _withCtx(scope => [_renderSlot(_ctx.$slots, column.typeSlot, {
          data: scope.row
        }, undefined, true)]),
        key: "1"
      } : column.template ? {
        name: "default",
        fn: _withCtx(scope => [_renderSlot(_ctx.$slots, column.templateSlot, {
          data: scope.row
        }, undefined, true)]),
        key: "2"
      } : undefined]), 1032, ["prop", "label", "width", "formatter", "align", "type", "show-overflow-tooltip"])), [[_vShow, column.show === undefined ? true : column.show]]);
    }), 128)), $options.filterLinkButtons && $options.filterLinkButtons.length > 0 ? (_openBlock(), _createBlock(_component_el_table_column, {
      key: 2,
      label: "操作",
      width: "240px"
    }, {
      default: _withCtx(scope => [$options.filterLinkButtons.length <= $props.showMoreButtonCount ? (_openBlock(), _createElementBlock("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.filterLinkButtons, item => {
        var _item$type;
        return _openBlock(), _createElementBlock(_Fragment, null, [(typeof item.show === 'function' ? item.show(scope.row) : true) ? (_openBlock(), _createBlock(_component_el_button, {
          type: (_item$type = item.type) !== null && _item$type !== void 0 ? _item$type : 'primary',
          link: "",
          key: item.code,
          onClick: () => {
            item.click(scope.row);
          },
          disabled: typeof item.disabled === 'function' ? item.disabled(scope.row) : item.disabled
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(typeof item.name === "function" ? item.name(scope.row) : item.name), 1)]),
          _: 2
        }, 1032, ["type", "onClick", "disabled"])) : _createCommentVNode("", true)], 64);
      }), 256))])) : (_openBlock(), _createElementBlock("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.filterLinkButtons.slice(0, 2), item => {
        var _item$type2;
        return _openBlock(), _createElementBlock(_Fragment, null, [(typeof item.show === 'function' ? item.show(scope.row) : true) ? (_openBlock(), _createBlock(_component_el_button, {
          link: "",
          key: item.code,
          type: (_item$type2 = item.type) !== null && _item$type2 !== void 0 ? _item$type2 : 'primary',
          onClick: () => {
            item.click(scope.row);
          },
          disabled: typeof item.disabled === 'function' ? item.disabled(scope.row) : item.disabled
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(typeof item.name === "function" ? item.name(scope.row) : item.name), 1)]),
          _: 2
        }, 1032, ["type", "onClick", "disabled"])) : _createCommentVNode("", true)], 64);
      }), 256)), _createVNode(_component_el_divider, {
        direction: "vertical"
      }), _createVNode(_component_el_dropdown, {
        onCommand: command => {
          $options.handleMoreCommand(command, scope.row);
        }
      }, {
        dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, null, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.filterLinkButtons.slice(2), item => {
            return _openBlock(), _createElementBlock(_Fragment, null, [(typeof item.show === 'function' ? item.show(scope.row) : true) ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
              command: item.code,
              key: item.code,
              disabled: typeof item.disabled === 'function' ? item.disabled(scope.row) : item.disabled
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(typeof item.name === "function" ? item.name(scope.row) : item.name), 1)]),
              _: 2
            }, 1032, ["command", "disabled"])) : _createCommentVNode("", true)], 64);
          }), 256))]),
          _: 2
        }, 1024)]),
        default: _withCtx(() => [_createElementVNode("span", _hoisted_7, [_createTextVNode("更多"), _createVNode(_component_el_icon, {
          class: "iconfont icon-down"
        })])]),
        _: 2
      }, 1032, ["onCommand"])]))]),
      _: 1
    })) : _createCommentVNode("", true)]),
    _: 3
  }, 8, ["data", "onSelectionChange", "onCurrentChange", "size", "row-key", "show-summary", "highlight-current-row"])), [[_directive_loading, $data.loading]]), $props.showPagination ? (_openBlock(), _createBlock(_component_el_pagination, {
    key: 1,
    layout: "total, prev, pager, next, sizes",
    total: $data.dataTotal,
    "page-size": $data.pageSize,
    "onUpdate:pageSize": _cache[0] || (_cache[0] = $event => $data.pageSize = $event),
    "current-page": $data.currentPage,
    "onUpdate:currentPage": _cache[1] || (_cache[1] = $event => $data.currentPage = $event),
    "page-sizes": $data.pageSizeOptions,
    class: "table-pagination"
  }, null, 8, ["total", "page-size", "current-page", "page-sizes"])) : (_openBlock(), _createElementBlock("div", _hoisted_8))])]);
}