import axios from 'axios';

/**
 * 获取待办数量
 * @param params
 * @returns {Promise<any | {code: number, message: string}>}
 */
export const taskCount = params => axios.post('/flow/flowRest/taskCount', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 获取待办列表
 * @param params
 * @returns {Promise<any | {code: number, message: string}>}
 */
export const myTaskList = params => axios.post('/flow/flowRest/myTaskList', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 获取流程实例当前的执行任务
 * @param processInstanceId 流程实例Id
 * @returns {Promise<any | {code: number, message: string}>}
 */
export const processTask = processInstanceId => axios.post('/flow/flowRest/processTask', {processInstanceId}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 获取任务池
 * @param params
 * @returns {Promise<any | {code: number, message: string}>}
 */
export const taskList = params => axios.post('/flow/flowCarRest/taskList', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 获取流程实例的历史步骤
 * @param params
 * @returns {Promise<any | {code: number, message: string}>}
 */
export const historyActivity = processInstanceId => axios.post('/flow/flowRest/historyActivity', {processInstanceId}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 认领任务
 * @param processInstanceId 流程实例Id
 * @param taskId 任务id
 * @returns {Promise<any | {code: number, message: string}>}
 */
export const claimTask = param => axios.post('/flow/flowRest/claimTask', param).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 指派任务
 * @param processInstanceId 流程实例Id
 * @param taskId 任务id
 * @returns {Promise<any | {code: number, message: string}>}
 */
export const delegateTask = (processInstanceId, taskId) => axios.post('/flow/flowRest/delegateTask', {
    processInstanceId,
    taskId
}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
