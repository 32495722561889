import axios from 'axios';

/**
 * 查询机构列表
 * @param params 查询参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const list = params => axios.post('/uac/org/list', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 查看机构信息
 * @param orgId 机构id
 * @return {Promise<AxiosResponse<any>>}
 */
export const detail = (orgId) => axios.post('/uac/org/detail', {orgId}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 创建机构
 * @param params 机构参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const create = params => axios.post('/uac/org/create', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 修改机构
 * @param params 机构参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const update = params => axios.post('/uac/org/update', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 删除机构
 * @param orgId 机构id
 * @return {Promise<AxiosResponse<any>>}
 */
export const del = (orgId) => axios.post('/uac/org/delete', {orgId}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 绑定权限
 * @param orgId 机构id
 * @param permissionIds 权限Id列表
 * @return {Promise<AxiosResponse<any>>}
 */
export const bindPermission = (orgId, permissionIds) => axios.post('/uac/org/bindPermission', {
    orgId,
    permissionIds
}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
