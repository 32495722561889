/**** 在停记录管理 ***/
import axios from 'axios';

/**
 * 查询在停记录列表信息
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const getList = params => axios.post('/business/rideCurrRecord/list', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const detail = params => axios.post('/business/rideCurrRecord/detail', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

export const getLogs = params => axios.post('/business/rideCurrRecord/getLogs', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

export const recordOut = id => axios.post('/business/rideCurrRecord/recordOut', {id}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

export const recordOutOneKey = (password) => axios.post('/business/rideCurrRecord/recordOutOneKey', {password}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

export const exportInfo = params => axios.post('/business/rideCurrRecord/export', params, {
    responseType: 'blob',
    timeout: 20000
}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
