export default {
  install: (app, options) => {
    app.config.globalProperties.$hasPermission = (value) => {
      const permissionCodes = options.store.getters['perm/getPerm'];
      if (Array.isArray(value)) {
        return value.filter(item => permissionCodes.some(v => v === item)).length > 0;
      } else {
        return permissionCodes.some(v => v === value);
      }
    }

    const permissionHandler = (el, binding) => {
      const {value} = binding;
      let isRemove = !app.config.globalProperties.$hasPermission(value);
      if (isRemove) {
        el.remove();
      }
    }

    app.directive('permission', {
      mounted: permissionHandler
    })

    app.provide('permission', options)
  }
}
