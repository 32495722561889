import * as rideCurrRecord from "./rideCurrRecord";
import * as rideRecord from "./rideRecord";
import * as holiday from "./holiday";
import * as oweFeeRecord from "./oweFeeRecord";
import * as recordRepresentation from "./recordRepresentation";
import * as feePayParameter from "./feePayParameter";
import * as feeRule from "./feeRule";
import * as maintenanceUser from "./maintenanceUser";
import * as electronicFence from "./electronicFence";
import * as faultInfo from "./faultInfo";
import * as depositRule from "./depositRule";
import * as feeStandardRule from "./feeStandardRule";
import * as feeStandardRuleAudit from "./feeStandardRuleAudit";
import * as shareTransactionFlow from "./shareTransactionFlow";
import * as customer from "./customer";
import * as transactionFlow from "./transactionFlow";
import * as customerSuggestInfo from "./customerSuggestInfo";
import * as task from "./task";
import * as dictionary from "./dictionary"
import * as np from "./np"
import * as park from "./park"
import * as workbench from "./workbench"
import * as chargingStation from "./chargingStation"
import * as blackList from "./blackList";
import * as whiteList from "./whiteList";
import * as batteryModel  from "./batteryModel";
import * as carWarehouse from "./carWarehouse";
import * as customerBalanceRecharge from "./customerBalanceRecharge";
import * as customerCoupon from "./customerCoupon";
import * as ridePromotion from "./ridePromotion";
import * as customerBalanceRefund from "./customerBalanceRefund";
import * as customerRideCard from "./customerRideCard";
import * as rideCard from "./rideCard";
import * as deviceOperationLog from "./deviceOperationLog";
import * as deviceController from "./deviceController";
import * as deviceMonitor from "./deviceMonitor";
import * as deviceBattery from "./deviceBattery";
import * as dataUpload from "./dataUpload";
import * as deviceVehicle from "./deviceVehicle";
import * as deviceFrame from "./deviceFrame";
import * as rechargePromotion from "./rechargePromotion";



export default {
    rideCurrRecord,
    rideRecord,
    shareTransactionFlow,
    oweFeeRecord,
    recordRepresentation,
    feeRule,
    electronicFence,
    faultInfo,
    depositRule,
    feeStandardRule,
    feeStandardRuleAudit,
    customer,
    transactionFlow,
    task,
    deviceOperationLog,
    maintenanceUser,
    customerSuggestInfo,
    holiday,
    feePayParameter,
    dictionary,
    np,
    chargingStation,
    park,
    whiteList,
    deviceBattery,
    deviceFrame,
    deviceMonitor,
    deviceController,
    blackList,
    dataUpload,
    deviceVehicle,
    batteryModel,
    carWarehouse,
    customerBalanceRecharge,
    customerCoupon,
    ridePromotion,
    customerBalanceRefund,
    customerRideCard,
    rideCard,
    rechargePromotion,
    workbench,
};
