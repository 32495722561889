/**
 * 权限管理
 */
export default {
  namespaced: true,
  state: {
    permission: [],
    menus: [],
    userName: '',
    userCode: '',
    userId: '',
    admin: false
  },
  getters: {
    getPerm(state) {
      return state.permission;
    },
    getMenus(state) {
      return state.menus;
    },
    getUserName(state) {
      return state.userName;
    },
    getUserCode(state) {
      return state.userCode;
    },
    getUserId(state) {
      return state.userId;
    },
    isAdmin(state) {
      return state.admin;
    },
    getFirstMenu(state, getters) {
      return function (menus) {
        if (menus.length > 0) {
          const menu = menus[0];
          if (menu.children && menu.children.length > 0) {
            return getters.getFirstMenu(menu.children);
          } else {
            return menu;
          }
        }
      }
    }
  },
  actions: {
    setPermission({commit}, permissions) {
      if (Array.isArray(permissions)) {
        commit('mergeState', {permission: permissions});
      } else {
        throw new TypeError('permissions is not Array');
      }
    },
    setMenus({commit}, menus) {
      if (Array.isArray(menus)) {
        commit('mergeState', {menus});
      } else {
        throw new TypeError('menus is not Array');
      }
    },
    setUserName({commit}, userName) {
      commit('mergeState', {userName});
    },
    setUserCode({commit}, userCode) {
      commit('mergeState', {userCode});
    },
    setUserId({commit}, userId) {
      commit('mergeState', {userId});
    },
    setAdmin({commit}, admin) {
      commit('mergeState', {admin});
    }
  },
  mutations: {
    /**
     * 通用改变状态的值
     * @param {Object} state        状态
     * @param {Object} mergeObject  需要改变的对象 {key: value}
     */
    mergeState(state, mergeObject) {
      for (const key in mergeObject) {
        state[key] = mergeObject[key];
      }
    }
  }
};
