import axios from 'axios';

/**
 * 查询api接口配置
 * @param params
 * @return {Promise<AxiosResponse<any>>}
 */
export const list = params => axios.post('/uac/requestPath/list', params).then(res => res ? res.data : {
  code: 500,
  message: '服务器无法连接,请稍后重试'
});

/**
 * api分配权限
 * @param url api地址
 * @param permissionIds 权限列表
 * @return {Promise<AxiosResponse<any>>}
 */
export const bindPermission = (url, permissionIds) => axios.post('/uac/requestPath/bindPermission', {
  url,
  permissionIds
}).then(res => res ? res.data : {
  code: 500,
  message: '服务器无法连接,请稍后重试'
});

/**
 * 查询权限绑定的Api
 * @param permissionId 权限Id
 * @return {Promise<AxiosResponse<any>>}
 */
export const listByPermission = permissionId => axios.post('/uac/requestPath/listByPermission', {permissionId}).then(res => res ? res.data : {
  code: 500,
  message: '服务器无法连接,请稍后重试'
});
