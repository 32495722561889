/**** 退款记录管理 ***/
import axios from 'axios';

/**
 * 查询退款记录列表信息
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const getList = params => axios.post('/business/balanceRefund/list', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

export const getLogs = params => axios.post('/business/balanceRefund/getLogs', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
