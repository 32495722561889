/**** 在停记录管理 ***/
import axios from 'axios';

/**
 * 查询欠费记录列表信息
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const getList = params => axios.post('/business/rideOwefeeRecord/list', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const getFeesByIds = params => axios.post('/business/rideOwefeeRecord/getFeesByIds', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});


export const exportInfo = params => axios.post('/business/rideOwefeeRecord/export', params, {
    responseType: 'blob',
    timeout: 20000
}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

export const getAllFee = params => axios.post('/business/rideOwefeeRecord/getAllFee', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const importInfo = params => axios.post('/business//import', params, {
    responseType: 'blob',
    timeout: 60000
}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
