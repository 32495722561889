import "core-js/modules/es.error.cause.js";
import logo from '@/assets/logo.png';
import { ElLoading, ElMessage } from 'element-plus';
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'

export default {
  name: 'Login',
  data() {
    let validatePass1 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入确认密码'));
      } else if (value !== this.changePwdForm.newPwd) {
        callback(new Error('两次密码不匹配'));
      } else {
        callback();
      }
    };
    return {
      logo: logo,
      logining: false,
      loginType: 'user',
      captchaImage: null,
      formItem: {
        orgCode: '',
        username: '',
        password: '',
        captchaCode: '',
        captchaId: null
      },
      ruleItem: {
        orgCode: [{
          required: true,
          message: '请输入机构编码',
          trigger: 'blur'
        }],
        username: [{
          required: true,
          message: '请输入用户名',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }, {
          min: 8,
          max: 11,
          message: '长度应在 8 到 11 个字符',
          trigger: 'blur'
        }],
        captchaCode: [{
          required: true,
          message: '请输入验证码',
          trigger: 'blur'
        }]
      },
      changePwdDialog: false,
      changePwdForm: {
        orgCode: '',
        userName: '',
        oldPwd: '',
        newPwd: '',
        newPwd1: '',
        captchaId: '',
        captchaCode: ''
      },
      changePwdCaptchaImage: null,
      changePwdFormRules: {
        orgCode: [{
          required: true,
          message: '请输入机构编码',
          trigger: 'blur'
        }],
        userName: [{
          required: true,
          message: '请输入用户名',
          trigger: 'blur'
        }],
        oldPwd: [{
          required: true,
          message: '请输入旧密码',
          trigger: 'blur'
        }, {
          min: 6,
          max: 18,
          message: '长度在 6 到 18 个字符',
          trigger: 'blur'
        }],
        newPwd: [{
          required: true,
          message: '请输入新密码',
          trigger: 'blur'
        }, {
          min: 8,
          max: 11,
          message: '长度在 8 到 11 个字符',
          trigger: 'blur'
        }],
        newPwd1: [{
          required: true,
          message: '请输入确认密码',
          trigger: 'blur'
        }, {
          min: 8,
          max: 11,
          message: '长度在 8 到 11 个字符',
          trigger: 'blur'
        }, {
          validator: validatePass1,
          trigger: 'blur'
        }],
        captchaCode: [{
          required: true,
          message: '请输入验证码',
          trigger: 'blur'
        }]
      },
      rememberMe: false
    };
  },
  methods: {
    handleSubmit(name) {
      this.$refs[name].validate(valid => {
        if (valid) {
          this.logining = true;
          // 发起请求，成功后跳转页面
          this.$api.normalLogin(this.formItem.orgCode, this.formItem.username, this.formItem.password, this.formItem.captchaId, this.formItem.captchaCode).then(res => {
            this.logining = false;
            if (res.code === 200) {
              sessionStorage.setItem('token', res.data.token);
              if (res.data.credentialsExpiredDay > 0) {
                sessionStorage.setItem('credentialsExpiredDay', res.data.credentialsExpiredDay);
              } else {
                sessionStorage.removeItem('credentialsExpiredDay');
              }
              // permissionCodes
              this.$store.dispatch('perm/setPermission', res.data.permissionCodes);
              // menus
              this.$store.dispatch('perm/setMenus', res.data.menus);
              // userName
              this.$store.dispatch('perm/setUserName', res.data.userName);
              // userCode
              this.$store.dispatch('perm/setUserCode', res.data.userCode);
              // userId
              this.$store.dispatch('perm/setUserId', res.data.userId);
              // admin
              this.$store.dispatch('perm/setAdmin', res.data.admin);
              if (res.data.menus.length > 1) {
                // 多个系统级菜单，跳转选择
                this.$router.replace('/system');
              } else {
                this.$router.replace(this.$route.query && this.$route.query.redirect ? this.$route.query.redirect : '/');
              }
            } else {
              this.getCaptcha();
              ElMessage.error(res.message);
              if (res.message === '密码已过期') {
                this.changePwdForm = {
                  orgCode: '',
                  userName: '',
                  oldPwd: '',
                  newPwd: '',
                  newPwd1: '',
                  captchaId: '',
                  captchaCode: ''
                };
                // 弹出修改密码框
                this.getChangePwdCaptcha();
                this.changePwdDialog = true;
              }
            }
          }).catch(error => {
            this.logining = false;
            console.log(error);
            ElMessage.error('登录失败');
          });
        } else {
          ElMessage.error('表单验证失败');
        }
      });
    },
    getCaptcha() {
      this.$api.getCaptcha().then(res => {
        if (res.code === 200) {
          this.formItem.captchaId = res.data.captchaId;
          this.captchaImage = res.data.captchaImage;
        }
      });
    },
    getChangePwdCaptcha() {
      this.$api.getCaptcha().then(res => {
        if (res.code === 200) {
          this.changePwdForm.captchaId = res.data.captchaId;
          this.changePwdCaptchaImage = res.data.captchaImage;
        }
      });
    },
    doChangePwd() {
      this.$refs.pwdForm.validate(valid => {
        if (valid) {
          const loading = ElLoading.service();
          this.logining = true;
          this.$api.uac.user.changePwdNotLogin(this.changePwdForm.orgCode, this.changePwdForm.userName, this.changePwdForm.oldPwd, this.changePwdForm.newPwd, this.changePwdForm.captchaId, this.changePwdForm.captchaCode).then(res => {
            if (res.code === 200) {
              this.changePwdDialog = false;
              ElMessage({
                message: res.message,
                type: 'success'
              });
            } else {
              this.getChangePwdCaptcha();
              ElMessage({
                message: res.message,
                type: 'warning'
              });
            }
          }).finally(() => {
            loading.close();
            this.logining = false;
          });
        }
      });
    }
  },
  beforeMount() {
    this.getCaptcha();
  }
};