import axios from 'axios';

/**
 * 查询费用规则
 * @param params
 * @returns {Promise<any | {code: number, message: string}>}
 */
export const getList = params => axios.post('/business/feeRule/list', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

export const create = params => axios.post('/business/feeRule/create', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

export const update = params => axios.post('/business/feeRule/update', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

export const del = feeRuleId => axios.post('/business/feeRule/delete', {feeRuleId}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

export const detail = feeRuleId => axios.post('/business/feeRule/detail', {feeRuleId}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const exportInfo = params => axios.post('/business/feeRule/export', params, {
    responseType: 'blob',
    timeout: 20000
}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
