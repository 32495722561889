import {createRouter, createWebHistory} from 'vue-router'
import Login from '../views/Login'
import store from "../store";

const routes = [
    {
        path: '/',
        component: () => import('@/layout/index-tag'),
        children: [{
            path: '/403',
            name: '403',
            component: () => import('@/views/error/403'),
            meta: {
                title: '403',
                addTag: false
            }
        }, {
            path: '/404',
            name: '404',
            component: () => import('@/views/error/404'),
            meta: {
                title: '404',
                addTag: false
            }
        }, {
            path: '/requestPath',
            name: 'RequestPath',
            component: () => import('@/views/uac/requestPath/index'),
            meta: {
                title: 'API管理',
                permission: '/requestPath'
            }
        }, {
            path: '/permission',
            name: 'Permission',
            component: () => import('@/views/uac/permission/index'),
            meta: {
                title: '菜单管理',
                permission: '/permission'
            },
        }, {
            path: '/permissionAdd',
            name: 'permissionAdd',
            component: () => import('@/views/uac/permission/add'),
            meta: {
                title: '维护菜单',
                permission: ['permission_add', 'permission_edit'],
                parent: '/permission'
            }
        }, {
            path: '/user',
            name: 'User',
            component: () => import('@/views/uac/user/index'),
            meta: {
                title: '用户管理',
                permission: '/user'
            }
        }, {
            path: '/userAdd',
            name: 'userAdd',
            component: () => import('@/views/uac/user/add'),
            meta: {
                title: '维护用户',
                permission: ['user_edit', 'user_add'],
                parent: '/user'
            }
        }, {
            path: '/userDetail',
            name: 'UserDetail',
            component: () => import('@/views/uac/user/detail'),
            meta: {
                title: '用户详情',
                permission: 'user_detail',
                parent: '/user'
            }
        }, {
            path: '/dept',
            name: 'Dept',
            component: () => import('@/views/uac/dept/index'),
            meta: {
                title: '部门管理',
                permission: '/dept'
            }
        }, {
            path: '/deptAdd',
            name: 'deptAdd',
            component: () => import('@/views/uac/dept/add'),
            meta: {
                title: '维护部门',
                permission: ['dept_add', 'dept_edit'],
                parent: '/dept'
            }
        }, {
            path: '/role',
            name: 'Role',
            component: () => import('@/views/uac/role/index'),
            meta: {
                title: '角色管理',
                permission: '/role'
            }
        }, {
            path: '/roleAdd',
            name: 'roleAdd',
            component: () => import('@/views/uac/role/add'),
            meta: {
                title: '维护角色',
                permission: ['role_edit', 'role_add'],
                parent: '/role'
            }
        }, {
            path: '/logDatas',
            name: 'logDatas',
            component: () => import('@/views/log/index'),
            meta: {
                title: '日志记录',
                permission: '/logDatas'
            }
        }, {
            path: '/org',
            name: 'Org',
            component: () => import('@/views/uac/org/index'),
            meta: {
                title: '机构管理',
                permission: '/org'
            }
        }, {
            path: '/orgAdd',
            name: 'orgAdd',
            component: () => import('@/views/uac/org/add'),
            meta: {
                title: '维护机构',
                permission: ['org_edit', 'org_add'],
                parent: '/org'
            }
        }, {
            path: '/area',
            name: 'Area',
            component: () => import('@/views/uac/area/index'),
            meta: {
                title: '区域管理',
                permission: '/area'
            }
        }, {
            path: '/areaAdd',
            name: 'areaAdd',
            component: () => import('@/views/uac/area/add'),
            meta: {
                title: '维护区域',
                permission: ['area_edit', 'area_add'],
                parent: '/area'
            }
        }, {
            path: '/rideCurrRecord',
            name: 'rideCurrRecord',
            component: () => import('@/views/business/rideCurrRecord/index'),
            meta: {
                title: '在停记录',
                permission: '/rideCurrRecord'
            }
        }, {
            path: '/rideRecord',
            name: 'rideRecord',
            component: () => import('@/views/business/rideRecord/index'),
            meta: {
                title: '停车记录',
                permission: '/rideRecord'
            }
        }, {
            path: '/gps',
            name: 'gps',
            component: () => import('@/views/business/rideRecord/gps'),
            meta: {
                title: '查看轨迹',
                permission: ['rideRecord_gps'],
                parent: '/rideRecord'
            }
        }, {
            path: '/flow',
            name: 'flow',
            component: () => import('@/views/business/flow/index'),
            meta: {
                title: '缴费流水',
                permission: '/flow'
            }
        }, {
            path: '/oweFeeRecord',
            name: 'oweFeeRecord',
            component: () => import('@/views/business/oweFeeRecord/index'),
            meta: {
                title: '欠费记录',
                permission: '/oweFeeRecord'
            }
        }, {
            path: '/recordRepresentation',
            name: 'recordRepresentation',
            component: () => import('@/views/business/recordRepresentation/index'),
            meta: {
                title: '订单申诉',
                permission: '/recordRepresentation'
            }
        }, {
            path: '/recordRepresentationDetail',
            name: 'recordRepresentationDetail',
            component: () => import('@/views/business/recordRepresentation/detail'),
            meta: {
                title: '详情',
                permission: ['recordRepresentation_detail'],
                parent: '/recordRepresentation'
            }
        }, {
            path: '/dictionary',
            name: 'dictionary',
            component: () => import('@/views/business/dictionary/index'),
            meta: {
                title: '数据字典',
                permission: '/dictionary'
            }
        }, {
            path: '/dictionaryEdit',
            name: 'dictionaryEdit',
            component: () => import('@/views/business/dictionary/edit'),
            meta: {
                title: '编辑数据字典分类信息',
                permission: ['dictionary_edit', 'dictionary_add'],
                parent: '/dictionary'
            }
        }, {
            path: '/dictionaryItemAdd',
            name: 'dictionaryItemAdd',
            component: () => import('@/views/business/dictionary/add'),
            meta: {
                title: '编辑数据字典明细信息',
                permission: ['dictionary_edit', 'dictionary_add'],
                parent: '/dictionary'
            }
        }, {
            path: '/depositRule',
            name: 'depositRule',
            component: () => import('@/views/business/depositRule/index'),
            meta: {
                title: '押金规则',
                permission: '/depositRule'
            }
        }, {
            path: '/depositRuleAdd',
            name: 'depositRuleAdd',
            component: () => import('@/views/business/depositRule/add'),
            meta: {
                title: '编辑押金规则',
                permission: ['depositRule_add', 'depositRule_edit'],
                parent: '/depositRule'
            }
        }, {
            path: '/electronicFence',
            name: 'electronicFence',
            component: () => import('@/views/business/electronicFence/index'),
            meta: {
                title: '服务区域',
                permission: '/depositRule'
            }
        }, {
            path: '/electronicFenceAdd',
            name: 'electronicFenceAdd',
            component: () => import('@/views/business/electronicFence/add'),
            meta: {
                title: '维护服务区域',
                permission: ['electronicFence_add', 'electronicFence_edit'],
                parent: '/electronicFence'
            }
        }, {
            path: '/faultInfo',
            name: 'faultInfo',
            component: () => import('@/views/business/faultInfo/index'),
            meta: {
                title: '故障类型',
                permission: '/faultInfo'
            }
        }, {
            path: '/faultInfoAdd',
            name: 'faultInfoAdd',
            component: () => import('@/views/business/faultInfo/add'),
            meta: {
                title: '维护故障类型',
                permission: ['faultInfo_add', 'faultInfo_edit'],
                parent: '/faultInfo'
            }
        }, {
            path: '/deviceController',
            name: 'deviceController',
            component: () => import('@/views/business/deviceController/index'),
            meta: {
                title: '车辆中控',
                permission: '/deviceController'
            }
        }, {
            path: '/deviceControllerAdd',
            name: 'deviceControllerAdd',
            component: () => import('@/views/business/deviceController/add'),
            meta: {
                title: '维护车辆中控',
                permission: ['deviceController_add', 'deviceController_edit'],
                parent: '/deviceController'
            }
        }, {
            path: '/deviceBattery',
            name: 'deviceBattery',
            component: () => import('@/views/business/deviceBattery/index'),
            meta: {
                title: '电池管理',
                permission: '/deviceBattery'
            }
        }, {
            path: '/deviceBatteryAdd',
            name: 'deviceBatteryAdd',
            component: () => import('@/views/business/deviceBattery/add'),
            meta: {
                title: '维护电池',
                permission: ['deviceBattery_add', 'deviceBattery_edit'],
                parent: '/deviceBattery'
            }
        }, {
            path: '/deviceVehicle',
            name: 'deviceVehicle',
            component: () => import('@/views/business/deviceVehicle/index'),
            meta: {
                title: '车辆管理',
                permission: '/deviceVehicle'
            }
        }, {
            path: '/deviceVehicleAdd',
            name: 'deviceVehicleAdd',
            component: () => import('@/views/business/deviceVehicle/add'),
            meta: {
                title: '维护车辆',
                permission: ['deviceFrame_add', 'deviceVehicle_edit'],
                parent: '/deviceVehicle'
            }
        }, {
            path: '/deviceFrame',
            name: 'deviceFrame',
            component: () => import('@/views/business/deviceFrame/index'),
            meta: {
                title: '车架管理',
                permission: '/deviceFrame'
            }
        }, {
            path: '/deviceFrameAdd',
            name: 'deviceFrameAdd',
            component: () => import('@/views/business/deviceFrame/add'),
            meta: {
                title: '维护车架',
                permission: ['deviceFrame_add', 'deviceFrame_edit'],
                parent: '/deviceFrame'
            }
        }, {
            path: '/maintenanceUser',
            name: 'maintenanceUser',
            component: () => import('@/views/business/maintenanceUser/index'),
            meta: {
                title: '运维用户',
                permission: '/maintenanceUser'
            }
        }, {
            path: '/maintenanceUserAdd',
            name: 'maintenanceUserAdd',
            component: () => import('@/views/business/maintenanceUser/add'),
            meta: {
                title: '运维用户',
                permission: ['maintenanceUser_add', 'maintenanceUser_edit'],
                parent: '/maintenanceUser'
            }
        }, {
            path: '/feeRule',
            name: 'feeRule',
            component: () => import('@/views/business/feeRule/index'),
            meta: {
                title: '收费规则',
                permission: '/feeRule'
            }
        }, {
            path: '/feeRuleAdd',
            name: 'feeRuleAdd',
            component: () => import('@/views/business/feeRule/add'),
            meta: {
                title: '编辑收费规则',
                permission: ['feeRule_add', 'feeRule_edit'],
                parent: '/feeRule'
            }
        },
            {
                path: '/np',
                name: 'np',
                component: () => import('@/views/business/ele/index'),
                meta: {
                    title: '禁停区管理',
                    permission: '/np'
                }
            },
            {
                path: '/npAdd',
                name: 'npAdd',
                component: () => import('@/views/business/ele/add.vue'),
                meta: {
                    title: '添加/修改禁停区',
                    permission: ['np_add', 'np_edit'],
                    parent: '/np'
                }
            },
            {
                path: '/park',
                name: 'park',
                component: () => import('@/views/business/elePark/index'),
                meta: {
                    title: '停车区管理',
                    permission: '/park'
                }
            },
            {
                path: '/parkAdd',
                name: 'parkAdd',
                component: () => import('@/views/business/elePark/add.vue'),
                meta: {
                    title: '添加/修改停车区',
                    permission: ['park_add', 'park_edit'],
                    parent: '/park'
                }
            },
            {
                path: '/feeStandardRuleAudit',
                name: 'feeStandardRuleAudit',
                component: () => import('@/views/business/feeStandardRuleAudit/index'),
                meta: {
                    title: '费率管理',
                    permission: '/feeStandardRuleAudit'
                }
            }, {
                path: '/feeStandardRuleAuditAdd',
                name: 'feeStandardRuleAuditAdd',
                component: () => import('@/views/business/feeStandardRuleAudit/add'),
                meta: {
                    title: '编辑费率',
                    permission: ['feeStandardRuleAudit_add', 'feeStandardRuleAudit_edit'],
                    parent: '/feeStandardRuleAudit'
                }
            }, {
                path: '/feeStandardRule',
                name: 'feeStandardRule',
                component: () => import('@/views/business/feeStandardRule/index'),
                meta: {
                    title: '已生效费率',
                    permission: '/feeStandardRule'
                }
            }, {
                path: '/customer',
                name: 'customer',
                component: () => import('@/views/business/customer/index'),
                meta: {
                    title: '车主管理',
                    permission: '/customer'
                }
            }, {
                path: '/myTask',
                name: 'myTask',
                component: () => import('@/views/flow/myTask/index'),
                meta: {
                    title: '我的任务',
                    permission: '/myTask'
                }
            }, {
                path: '/taskPool',
                name: 'taskPool',
                component: () => import('@/views/flow/taskPool/index'),
                meta: {
                    title: '任务池',
                    permission: '/taskPool'
                }
            }, {
                path: '/taskConfig',
                name: 'TaskConfig',
                component: () => import('@/views/business/taskConfig/index'),
                meta: {
                    title: '定时任务',
                    permission: '/taskConfig'
                }
            }, {
                path: '/taskAdd',
                name: 'TaskAdd',
                component: () => import('@/views/business/taskConfig/add'),
                meta: {
                    title: '维护定时任务',
                    permission: ['taskConfig_create', 'taskConfig_edit'],
                    parent: '/taskConfig'
                }
            }, {
                path: '/feePayParameter',
                name: 'feePayParameter',
                component: () => import('@/views/business/feePayParameter/index'),
                meta: {
                    title: '支付参数',
                    permission: '/feePayParameter'
                }
            }, {
                path: '/feePayParameterAdd',
                name: 'feePayParameterAdd',
                component: () => import('@/views/business/feePayParameter/add'),
                meta: {
                    title: '维护支付参数',
                    permission: ['feePayParameter_add', 'feePayParameter_edit'],
                    parent: '/feePayParameter'
                }
            }, {
                path: '/holiday',
                name: 'holiday',
                component: () => import('@/views/business/holiday/index'),
                meta: {
                    title: '节假日',
                    permission: '/holiday'
                }
            }, {
                path: '/dataUpload',
                name: 'dataUpload',
                component: () => import('@/views/business/dataUpload/index'),
                meta: {
                    title: '文件管理',
                    permission: '/dataUpload'
                }
            }, {
                path: '/customerSuggestInfo',
                name: 'customerSuggestInfo',
                component: () => import('@/views/business/customerSuggestInfo/index'),
                meta: {
                    title: '车主意见',
                    permission: '/customerSuggestInfo'
                }
            }, {
                path: '/chargingStation',
                name: 'chargingStation',
                component: () => import('@/views/business/chargingStation/index'),
                meta: {
                    title: '充电站管理',
                    permission: '/chargingStation'
                }
            }, {
                path: '/blackList',
                name: 'blackList',
                component: () => import('@/views/business/blackList/index'),
                meta: {
                    title: '黑名单',
                    permission: '/blackList'
                }
            }, {
                path: '/bikeBlackListAdd',
                name: 'bikeBlackListAdd',
                component: () => import('@/views/business/blackList/add'),
                meta: {
                    title: '维护黑名单',
                    permission: ['bikeBlackList_add', 'bikeBlackList_edit'],
                    parent: '/blackList'
                }
            }, {
                path: '/whiteList',
                name: 'whiteList',
                component: () => import('@/views/business/whiteList/index'),
                meta: {
                    title: '白名单',
                    permission: '/whiteList'
                }
            }, {
                path: '/monitorBattery',
                name: 'monitorBattery',
                component: () => import('@/views/business/monitorInfo/monitorBattery'),
                meta: {
                    title: '电池监控',
                    permission: '/monitorBattery'
                }
            },
            {
                path: '/workbenchIndex',
                name: 'workbenchIndex',
                component: () => import('@/views/business/workbench/index'),
                meta: {
                    title: '工作台首页',
                    permission: '/workbenchIndex'
                }
            }, {
                path: '/monitorController',
                name: 'monitorController',
                component: () => import('@/views/business/monitorInfo/monitorController'),
                meta: {
                    title: '车锁监控',
                    permission: '/monitorController'
                }
            }, {
                path: '/monitorFrame',
                name: 'monitorFrame',
                component: () => import('@/views/business/monitorInfo/monitorFrame'),
                meta: {
                    title: '车架监控',
                    permission: '/monitorFrame'
                }
            },

            {
                path: '/bikeWhiteListAdd',
                name: 'bikeWhiteListAdd',
                component: () => import('@/views/business/whiteList/add'),
                meta: {
                    title: '维护白名单',
                    permission: ['bikeWhiteList_add', 'bikeWhiteList_edit'],
                    parent: '/whiteList'
                }
            }, {
                path: '/chargingStationAdd',
                name: 'chargingStationAdd',
                component: () => import('@/views/business/chargingStation/add'),
                meta: {
                    title: '新增',
                    permission: ['chargingStation_add', 'chargingStation_edit'],
                    parent:'/chargingStation'
                }
            },
            {
                path: '/batteryModel',
                name: 'batteryModel',
                component: () => import('@/views/business/batteryModel/index'),
                meta: {
                    title: '电池型号',
                    permission: '/batteryModel'
                }
            },
            {
                path: '/batteryModelAdd',
                name: 'batteryModelAdd',
                component: () => import('@/views/business/batteryModel/add'),
                meta: {
                    title: '维护电池型号',
                    permission: ['batteryModel_add', 'batteryModel_edit'],
                    parent: '/batteryModel'
                }
            },
            {
                path: '/carWarehouse',
                name: 'carWarehouse',
                component: () => import('@/views/business/carWarehouse/index'),
                meta: {
                    title: '车辆仓库管理',
                    permission: '/carWarehouse'
                }
            },
            {
                path: '/carWarehouseAdd',
                name: 'carWarehouseAdd',
                component: () => import('@/views/business/carWarehouse/add'),
                meta: {
                    title: '维护车辆仓库',
                    permission: ['carWarehouse_add', 'carWarehouse_edit'],
                    parent: '/carWarehouse'
                }
            }, {
                path: '/customerBalanceRecharge',
                name: 'customerBalanceRecharge',
                component: () => import('@/views/business/customerBalanceRecharge/index'),
                meta: {
                    title: '充值记录',
                    permission: '/customerBalanceRecharge'
                }
            }, {
                path: '/customerCoupon',
                name: 'customerCoupon',
                component: () => import('@/views/business/customerCoupon/index'),
                meta: {
                    title: '用户优惠券',
                    permission: '/customerCoupon'
                }
            }, {
                path: '/ridePromotion',
                name: 'ridePromotion',
                component: () => import('@/views/business/ridePromotion/index'),
                meta: {
                    title: '活动管理',
                    permission: '/ridePromotion'
                }
            }, {
                path: '/ridePromotionAdd',
                name: 'ridePromotionAdd',
                component: () => import('@/views/business/ridePromotion/add'),
                meta: {
                    title: '维护活动管理信息',
                    permission: ['ridePromotion_edit', 'ridePromotion_add'],
                    parent: '/parkPromotion'
                }
            }, {
                path: '/customerBalanceRefund',
                name: 'customerBalanceRefund',
                component: () => import('@/views/business/customerBalanceRefund/index'),
                meta: {
                    title: '退款记录',
                    permission: '/customerBalanceRefund'
                }
            }, {
                path: '/customerRideCard',
                name: 'customerRideCard',
                component: () => import('@/views/business/customerRideCard/index'),
                meta: {
                    title: '用户骑行卡',
                    permission: '/customerRideCard'
                }
            }, {
                path: '/rideCard',
                name: 'rideCard',
                component: () => import('@/views/business/rideCard/index'),
                meta: {
                    title: '骑行卡',
                    permission: '/rideCard'
                }
            }, {
                path: '/rideCardAdd',
                name: 'rideCardAdd',
                component: () => import('@/views/business/rideCard/add'),
                meta: {
                    title: '维护骑行卡',
                    permission: ['rideCard_add', 'rideCard_edit'],
                    parent: '/rideCard'
                }
            }, {
                path: '/rechargePromotion',
                name: 'rechargePromotion',
                component: () => import('@/views/business/rechargePromotion/index'),
                meta: {
                    title: '充值活动',
                    permission: '/rechargePromotion'
                }
            }, {
                path: '/rechargePromotionAdd',
                name: 'rechargePromotionAdd',
                component: () => import('@/views/business/rechargePromotion/add'),
                meta: {
                    title: '维护充值活动',
                    permission: ['rechargePromotion_add', 'rechargePromotion_edit'],
                    parent: '/rechargePromotion'
                }
            },

        ]
    }, {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            title: '登录',
            addTag: false
        }
    }, {
        path: '/system',
        name: 'Systems',
        component: () => import('@/views/Systems'),
        meta: {
            title: '选择系统',
            addTag: false
        }
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    let token = sessionStorage.getItem('token');
    if (!token && to.name !== 'Login') {
        // 登录验证
        next({
            name: 'Login',
            query: {redirect: to.fullPath},
        });
    } else {
        let path = from.path;
        let menuClick = sessionStorage.getItem('menuClick');
        // 页面的增加等菜单，退出的时候删除缓存
        if (path && from.meta && from.meta.parent && !menuClick) {
            store.dispatch('page/closePage', path);
        }
        sessionStorage.removeItem('menuClick');
        // 首页
        if (to.path === '/') {
            const first = store.getters['perm/getFirstMenu'](store.getters['perm/getMenus']);
            if (first) {
                next({
                    path: first.permissionCode
                });
            }
        }
        const toPermission = to.meta && to.meta.permission ? to.meta.permission : to.name;
        if (toPermission === undefined) {
            next({
                name: '404'
            });
        } else if (toPermission === 'Systems') {
            let title = to.meta && to.meta.title ? to.meta.title : to.name;
            document.title = `${title} - ${process.env.VUE_APP_PRODUCT_NAME}`;
            next();
        } else {
            // 权限验证
            const permissionCodes = store.getters['perm/getPerm'];
            let hasPermission;
            if (Array.isArray(toPermission)) {
                hasPermission = toPermission.filter(item => permissionCodes.some(v => v === item)).length > 0;
            } else {
                hasPermission = permissionCodes.some(v => v === toPermission);
            }
            const notPermissions = ['403', '404', 'Login'];
            if (!hasPermission && notPermissions.indexOf(to.name) < 0) {
                next({
                    name: '403'
                });
            } else {
                let title = to.meta && to.meta.title ? to.meta.title : to.name;
                if (to.meta) {
                    document.title = `${title} - ${process.env.VUE_APP_PRODUCT_NAME}`;
                    // 默认需要加入tag
                    let addTag = to.meta.addTag;
                    addTag = addTag === undefined ? true : addTag;
                    const closable = to.meta.closable;
                    if (addTag) {
                        const page = {
                            path: to.path,
                            title: title,
                            name: to.name,
                            closable: closable === undefined ? true : closable // 默认可关闭
                        }
                        store.dispatch('page/addOpenedPage', page);
                        store.dispatch('page/setCurrentPage', page);
                    }
                }
                next();
            }
        }
    }
})

export default router
