/**** 在停记录管理 ***/
import axios from 'axios';

/**
 * 查询订单申诉列表信息
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const getList = params => axios.post('/business/recordRepresentation/list', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
/**
 * 查询详情
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const detail = params => axios.post('/business/recordRepresentation/detail', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

export const flowAudit = params => axios.post('/business/recordRepresentation/flowAudit', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const exportInfo = params => axios.post('/business/recordRepresentation/export', params, {
    responseType: 'blob',
    timeout: 20000
}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
export const refundExport = params => axios.post('/business/recordRepresentation/refundExport', params, {
    responseType: 'blob',
    timeout: 20000
}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});