// 定时任务 api js
import axios from 'axios';

/**
 * 查询定时任务
 * @param params
 * @returns {Promise<any | {code: number, message: string}>}
 */
export const list = params => axios.post('/business/taskConfig/list', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 启动定时任务
 * @param className 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const start = className => axios.post('/business/taskConfig/start', {className}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 停止定时任务
 * @param className 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const stop = className => axios.post('/business/taskConfig/stop', {className}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});


/**
 * 创建定时任务
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const create = params => axios.post('/business/taskConfig/create', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 修改定时任务
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const update = params => axios.post('/business/taskConfig/update', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 查看定时任务信息
 * @param className 任务类名称
 * @return {Promise<AxiosResponse<any>>}
 */
export const detail = className => axios.post('/business/taskConfig/detail', {className}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 删除定时任务
 * @param className 任务类名称
 * @return {Promise<AxiosResponse<any>>}
 */
export const remove = className => axios.post('/business/taskConfig/remove', {className}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 立即执行定时任务
 * @param className 任务类名称
 * @return {Promise<AxiosResponse<any>>}
 */
export const run = className => axios.post('/business/taskConfig/run', {className}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

