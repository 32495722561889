/**** 白名单管理 ***/
import axios from 'axios';

/**
 * 查询白名单列表信息
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const getList = params => axios.post('/business/whiteList/list', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 *   白名单详情
 * @param params
 * @returns {Promise<any | {code: number, message: string}>}
 */
export const detail = params => axios.post('/business/whiteList/detail', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
/**
 * 删除白名单
 * @param roadPointId 白名单id
 * @returns {Promise<any | {code: number, message: string}>}
 */
export const del = params => axios.post('/business/whiteList/del', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 删除白名单
 * @param roadPointId 白名单id
 * @returns {Promise<any | {code: number, message: string}>}
 */
export const addWhite = params => axios.post('/business/whiteList/addWhite', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 创建白名单
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const create = params => axios.post('/business/whiteList/create', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 修改白名单信息信息
 * @param params 参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const update = params => axios.post('/business/whiteList/update', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});


export const exportTemplate = () => axios.post('/business/whiteList/exportTemplate', null, {
    responseType: 'blob',
    timeout: 20000
}).then(res => res ? res.data : {
    code: 500,
    msg: '服务器无法连接,请稍后重试',
});
