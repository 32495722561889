import axios from 'axios';

/**
 * 查询角色
 * @param params 查询参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const list = params => axios.post('/uac/role/list', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 创建角色
 * @param params 角色参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const create = params => axios.post('/uac/role/create', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 修改角色
 * @param params 角色参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const update = params => axios.post('/uac/role/update', params).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 删除角色
 * @param roleId 角色id
 * @return {Promise<AxiosResponse<any>>}
 */
export const del = (roleId) => axios.post('/uac/role/delete', {roleId}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 查看角色
 * @param roleId 角色id
 * @return {Promise<AxiosResponse<any>>}
 */
export const detail = (roleId) => axios.post('/uac/role/detail', {roleId}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 绑定用户
 * @param roleId 角色id
 * @param userIds 用户Id列表
 * @return {Promise<AxiosResponse<any>>}
 */
export const bindUser = (roleId, userIds) => axios.post('/uac/role/bindUser', {
    roleId,
    userIds
}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 获取用户已绑定的角色
 * @param userId 用户Id
 * @return {Promise<AxiosResponse<any>>}
 */
export const queryByUser = (userId) => axios.post('/uac/role/queryByUser', {userId}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 绑定权限
 * @param roleId 角色id
 * @param permissionIds 权限Id列表
 * @return {Promise<AxiosResponse<any>>}
 */
export const bindPermission = (roleId, permissionIds) => axios.post('/uac/role/bindPermission', {
    roleId,
    permissionIds
}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 获取权限已绑定的角色
 * @param permissionId 权限id
 * @return {Promise<AxiosResponse<any>>}
 */
export const queryByPermission = (permissionId) => axios.post('/uac/role/queryByPermission', {permissionId}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 绑定权限
 * @param roleId 角色id
 * @param areaIds 区域Id列表
 * @return {Promise<AxiosResponse<any>>}
 */
export const bindArea = (roleId, areaIds) => axios.post('/uac/role/bindArea', {
    roleId,
    areaIds
}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 获取区域已绑定的角色
 * @param areaId 区域Id
 * @return {Promise<AxiosResponse<any>>}
 */
export const queryByArea = (areaId) => axios.post('/uac/role/queryByArea', {areaId}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 查询角色已绑定的停车场
 * @param roleId 角色id
 * @return {Promise<AxiosResponse<any>>}
 */
export const getBindParkingIot = (roleId) => axios.post('/uac/role/getBindParkingIot', {roleId}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});

/**
 * 角色绑定停车场
 * @param roleId 角色id
 * @param parkingIotIds 停车场
 * @return {Promise<AxiosResponse<any>>}
 */
export const bindParkingIot = (roleId, parkingIotIds) => axios.post('/uac/role/bindParkingIot', {roleId, parkingIotIds}).then(res => res ? res.data : {
    code: 500,
    message: '服务器无法连接,请稍后重试'
});
